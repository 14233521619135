.swiper {
    width: 100%;
    height: 100%;
  }
 
  #canais .swiper-slide {
    text-align: center;
    background: #1c1c1c;
    padding: 20px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    height: auto;
  }
  
  #canais.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;

  }
  
  #canais .swiper {
    margin-left: auto;
    margin-right: auto;

  }
  #canais .swiper-button-prev,
  #canais .swiper-button-next{
    color: #f3761c;
  }
  #canais .swiper-pagination-bullet-active{
   background-color: #f3761c;

  }
  #canais .swiper-pagination-bullet{
    width: 15px;
    height: 15px;
    color: #fff;
  }


