#vantagens {
    width: 100%;
    background: rgb(0,26,76);
    background: linear-gradient(343deg, rgba(0,26,76,1) 30%, rgba(0,35,74,1) 73%);
    padding-left: 40px;
    padding-right: 40px;
}
#vantagens .title{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}
/* #vantagens .shadow{
    background: rgb(54, 88, 150);
    background: linear-gradient(360deg, rgba(60, 95, 161, 0.26) 30%, rgb(14, 75, 146) 73%);
    border-radius: 30px;
} */
#vantagens svg{
    color: #ff6100;
}