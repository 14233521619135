.clients a {
    text-decoration: none;
}
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #0880e8;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #2194f7;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #0880e8;
    border-top-color: #bfe0fd;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1s linear infinite;
  }
  
  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
  #topbar {
    
    background-color: rgb(255, 255, 255);
    height: 50px;
    padding: 0;
    font-size: 14px;
    transition: all 0.5s;
    overflow: hidden;
    color: rgb(35, 35, 35);
    font-weight: 100;
    z-index: 996;
    text-decoration: none;
  }
  
  #topbar.topbar-scrolled {
    top: -60px;
  }
  
  #topbar .contact-info a {
    line-height: 0;
    color: rgb(35, 35, 35);
    transition: 0.3s;
    text-decoration: none;

  }
  
  #topbar .contact-info a:hover {
    text-decoration: none;
  }
  
  #topbar .contact-info i {
    color:rgb(35, 35, 35);
    line-height: 0;
    margin-right: 5px;
  }
  
  #topbar .contact-info .phone-icon {
    margin-left: 15px;
  }
  
  #topbar .cta {
    background: transparent;
  }
  
  #topbar .cta a {
    color: #fff;
    background:#E4641C;
    padding: 6px 24px 8px 24px;
    display: inline-block;
    transition: 0.3s;
    border-radius: 50px;
    text-decoration: none;
    
}
  
  #topbar .cta a:hover {
    background: #ff7627;
  }

  /*--------------------------------------------------------------
  # Header Section
  --------------------------------------------------------------*/

  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    height: 80vh;
    overflow: hidden;
    position: relative;
    background: url("https://img.freepik.com/vetores-gratis/fundo-futurista-poli_23-2148453991.jpg") top center;
    background-size: cover;
    position: relative;
    margin-bottom: -90px;
    transition: 0.3s;
  }
  
  #hero:before {
    content: "";
    background: rgba(6, 101, 183, 0.9);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 700;
  }
  
  #hero p {
    width: 80%;
    animation-delay: 0.4s;
    margin: 0 auto 30px auto;
    color: #fff;
  }
  
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 10%;
  }
  
  #hero .carousel-control-next-icon,
  #hero .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
  }
  
  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    animation-delay: 0.8s;
    border: 2px solid#E4641C;
  }
  
  #hero .btn-get-started:hover {
    background:#E4641C;
    color: #fff;
    text-decoration: none;
  }
  
  @media (min-width: 1024px) {
    #hero p {
      width: 60%;
    }
  
    #hero .carousel-control-prev,
    #hero .carousel-control-next {
      width: 5%;
    }
  }
  
  @media (max-width: 768px),
  (max-height: 700px) {
    #hero {
      height: 120vh;
      padding: 100px 0;
    }
  
    #hero h2 {
      font-size: 28px;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #f1f8ff;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #00284A;
  }
  
  .section-title p {
    margin-bottom: 0;
    font-style: italic;
  }
  
  /*--------------------------------------------------------------
  # Icon Boxes
  --------------------------------------------------------------*/
  .icon-boxes {
    padding-top: 0;
    position: relative;
    z-index: 100;
  }
  
  .icon-boxes .icon-box {
    padding: 40px 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 5px 10px 29px 0 rgba(68, 88, 144, 0.2);
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  .icon-boxes .icon {
    margin: 0 auto 20px auto;
    display: inline-block;
    text-align: center;
  }
  
  .icon-boxes .icon i {
    font-size: 36px;
    line-height: 1;
    color:#E4641C;
  }
  
  .icon-boxes .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  

  
  .icon-boxes .title a {
    color: #05579e;
  }
  
  .icon-boxes .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
    color: #777777;
  }
  
  /*--------------------------------------------------------------
  # About Us
  --------------------------------------------------------------*/
  .about {
    padding: 140px 0;
    background: url("https://img.freepik.com/vetores-gratis/fundo-futurista-poli_23-2148453991.jpg") top center no-repeat;
    position: relative;
  }
  
  .about:before {
    content: "";
    background: rgba(255, 255, 255, 0.75);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  .about .container {
    position: relative;
  }
  
  .about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .about .content ul li+li {
    margin-top: 10px;
  }
  
  .about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #0880e8;
    line-height: 1;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .content .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #0880e8;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #0880e8;
  }
  
  .about .content .btn-learn-more:hover {
    background: #0880e8;
    color: #fff;
    text-decoration: none;
  }
  
  @media (max-width: 992px) {
    .about {
      padding: 60px 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
  .canais {
    background-color: #0c0c0c;
   height: 8%;
   padding: 30px;
  }
  .canais .g{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .canais img{
    width: 60%;
    margin: auto;
    display: block;
  }
  .canais .swiper-slide img {
    width: 80px;
    opacity: 0.5;
    transition: 0.3s;
  }
  
  .canais .swiper-slide:hover img {
    opacity: 1;
  }
  
 
  
  /*--------------------------------------------------------------
  # Why Us
  --------------------------------------------------------------*/
  .why-us {
    background: #f1f8ff;
    padding: 0;
  }
  
  .why-us .content {
    padding: 60px 100px 0 100px;
  }
  
  .why-us .content h3 {
    font-weight: 400;
    font-size: 34px;
  }
  
  .why-us .content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
  }
  
  .why-us .content p {
    font-size: 15px;
    color: #848484;
  }
  
  .why-us .video-box {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 400px;
    position: relative;
  }
  
  .why-us .accordion-list {
    padding: 0 100px 60px 100px;
  }
  
  .why-us .accordion-list ul {
    padding: 0;
    list-style: none;
  }
  
  .why-us .accordion-list li+li {
    margin-top: 15px;
  }
  
  .why-us .accordion-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
  }
  
  .why-us .accordion-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 30px;
    outline: none;
    cursor: pointer;
  }
  
  .why-us .accordion-list span {
    color: #0880e8;
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
  }
  
  .why-us .accordion-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .why-us .accordion-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  
  .why-us .accordion-list .icon-show {
    display: none;
  }
  
  .why-us .accordion-list a.collapsed {
    color: #343a40;
  }
  
  .why-us .accordion-list a.collapsed:hover {
    color: #0880e8;
  }
  
  .why-us .accordion-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .why-us .accordion-list a.collapsed .icon-close {
    display: none;
  }
  
  .why-us .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#0880e8 50%, rgba(8, 128, 232, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .why-us .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .why-us .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(8, 128, 232, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .why-us .play-btn:hover::after {
    border-left: 15px solid #0880e8;
    transform: scale(20);
  }
  
  .why-us .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }
  
  @media (max-width: 1024px) {
  
    .why-us .content,
    .why-us .accordion-list {
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  @media (max-width: 992px) {
    .why-us .content {
      padding-top: 30px;
    }
  
    .why-us .accordion-list {
      padding-bottom: 30px;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services .icon-box {
    margin-bottom: 20px;
    padding: 50px 40px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .services .icon-box i {
    float: left;
    color:#E4641C;
    font-size: 40px;
    line-height: 0;
  }
  
  .services .icon-box h4 {
    margin-left: 70px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .icon-box h4 a {
    color: #05579e;
    transition: 0.3s;
  }
  
  .services .icon-box h4 a:hover {
    color: #0880e8;
  }
  
  .services .icon-box p {
    margin-left: 70px;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Cta
  --------------------------------------------------------------*/
  .cta {
    background: linear-gradient(rgba(5, 74, 133, 0.8), rgba(5, 74, 133, 0.9)), url("https://hdflgabrcqndyvcnyjtn.supabase.co/storage/v1/object/public/imagens/banners/bg-cta-planos?t=2024-01-04T14%3A38%3A09.979Z") fixed center center;
    background-size: cover;
    padding: 120px 0;
  }
  
  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: #fff;
  }
  
  .cta .cta-btn {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 8px 26px;
    border-radius: 2px;
    transition: 0.5s;
    margin: 10px;
    border-radius: 50px;
    border: 2px solid#E4641C;
    color: #fff;
  }
  
  .cta .cta-btn:hover {
    background:#E4641C;
  }
  
  @media (max-width: 1024px) {
    .cta {
      background-attachment: scroll;
    }
  }
  
  @media (min-width: 769px) {
    .cta .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

    /*--------------------------------------------------------------
  # Novidades
  --------------------------------------------------------------*/
  .novidades{
    margin: 0px;
    margin-top: -60px;
    margin-bottom: -60px;
  }
  .novidades .banner img{
    width:100% ;
 
  }

  
  /*--------------------------------------------------------------
  # Portfoio
  --------------------------------------------------------------*/
  .portfoio #portfolio-flters {
    padding: 0;
    margin: 0 auto 25px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
  }
  
  .portfoio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 18px 10px 18px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: #444444;
    margin: 0 3px 10px 3px;
    transition: all ease-in-out 0.3s;
    border-radius: 50px;
  }
  
  .portfoio #portfolio-flters li:hover,
  .portfoio #portfolio-flters li.filter-active {
    color: #fff;
    background: #0880e8;
  }
  
  .portfoio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  
  .portfoio .portfolio-item {
    margin-bottom: 30px;
    overflow: hidden;
  }
  
  .portfoio .portfolio-item img {
    position: relative;
    top: 0;
    transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .portfoio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: -50px;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    background: #0880e8;
    padding: 15px 20px;
  }
  
  .portfoio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
  }
  
  .portfoio .portfolio-item .portfolio-info p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .portfoio .portfolio-item .portfolio-info .preview-link,
  .portfoio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 50px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: white;
    transition: ease-in-out 0.3s;
  }
  
  .portfoio .portfolio-item .portfolio-info .preview-link:hover,
  .portfoio .portfolio-item .portfolio-info .details-link:hover {
    color: #8ec8fb;
  }
  
  .portfoio .portfolio-item .portfolio-info .details-link {
    right: 15px;
  }
  
  .portfoio .portfolio-item:hover img {
    top: -30px;
  }
  
  .portfoio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
    padding-top: 40px;
  }
  
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #0880e8;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #0880e8;
  }
  
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(246, 176, 36, 0.08);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
  .team .member {
    position: relative;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 10px;
    background: #fff;
  }
  
  .team .member .pic {
    overflow: hidden;
    width: 180px;
    border-radius: 50%;
  }
  
  .team .member .pic img {
    transition: ease-in-out 0.3s;
  }
  
  .team .member:hover img {
    transform: scale(1.1);
  }
  
  .team .member .member-info {
    padding-left: 30px;
  }
  
  .team .member h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #05579e;
  }
  
  .team .member span {
    display: block;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
  }
  
  .team .member span::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #bfe0fd;
    bottom: 0;
    left: 0;
  }
  
  .team .member p {
    margin: 10px 0 0 0;
    font-size: 14px;
  }
  
  .team .member .social {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .team .member .social a {
    transition: ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    background: #ecf6fe;
    color: #0665b7;
  }
  
  .team .member .social a i {
    font-size: 16px;
    margin: 0 2px;
  }
  
  .team .member .social a:hover {
    background: #0880e8;
    color: #fff;
  }
  
  .team .member .social a+a {
    margin-left: 8px;
  }
  
  /*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
  .pricing{
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("https://hdflgabrcqndyvcnyjtn.supabase.co/storage/v1/object/public/imagens/banners/novidades/pricing-bg.jpg?t=2023-12-01T14%3A22%3A17.629Z") fixed center center;
    background-size: cover;
    padding: 120px 0;
  }
  .pricing .section-title h2{
  color: #fff;
  }
  .pricing .section-title p{
    color: #ffa200;
  }
  .pricing .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }
  
  .pricing h3 {
    font-weight: 400;
    margin: -20px -20px 20px -20px;
    padding: 20px 15px;
    font-size: 18px;
    font-weight: 700;
    color: #05579e;
    background: #f8f8f8;
  }
  
  .pricing h4 {
    font-size: 36px;
    color: #0880e8;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }
  
  .pricing h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }
  
  .pricing h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
  
  .pricing ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }
  
  .pricing ul li {
    padding-bottom: 16px;
  }
  
  .pricing ul i {
    color: #0880e8;
    font-size: 18px;
    padding-right: 4px;
  }
  
  .pricing ul .no {
    color: #ccc;
    text-decoration: line-through;
  }
  
  .pricing .btn-wrap {
    margin: 20px -20px -20px -20px;
    padding: 20px 15px;
    background: #f8f8f8;
    text-align: center;
  }
  
  .pricing .btn-buy {
    background: #0880e8;
    display: inline-block;
    padding: 8px 35px 10px 35px;
    border-radius: 50px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    transition: 0.3s;
  }
  
  .pricing .btn-buy:hover {
    background: #2b99f8;
  }
  
  .pricing .featured h3 {
    color: #fff;
    background: #0880e8;
  }
  
  .pricing .advanced {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 0.7em;
    padding: 1px 0 3px 0;
    background: #E4641C;
    color: #fff;
  }
  .pricing .promo {
    width: 200px;
    position: absolute;
    top: 50px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 0.7em;
    padding: 1px 0 3px 0;
    background: #000;
    color: #fff;
  }
  .pricing .nota{
    position: absolute;
  margin-top:80px;
  margin-left: 10px;

  }
  /*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
  .faq .faq-list {
    padding: 0 100px;
  }
  
  .faq .faq-list ul {
    padding: 0;
    list-style: none;
  }
  
  .faq .faq-list li+li {
    margin-top: 15px;
  }
  
  .faq .faq-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
  }
  
  .faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
  }
  
  .faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: #75bdfa;
  }
  
  .faq .faq-list .icon-show,
  .faq .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  
  .faq .faq-list .icon-show {
    display: none;
  }
  
  .faq .faq-list a.collapsed {
    color: #343a40;
  }
  
  .faq .faq-list a.collapsed:hover {
    color: #0880e8;
  }
  
  .faq .faq-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .faq .faq-list a.collapsed .icon-close {
    display: none;
  }
  
  @media (max-width: 1200px) {
    .faq .faq-list {
      padding: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info-box {
    color: #444444;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 20px 0 30px 0;
  }
  
  .contact .info-box i {
    font-size: 32px;
    color: #E4641C;
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted #f5a0a0;
  }
  
  .contact .info-box h3 {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 700;
    margin: 10px 0;
  }
  
  .contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/
  .blog {
    padding: 40px 0 20px 0;
  }
  
  .blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
  }
  
  .blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .blog .entry .entry-title a {
    color: #054a85;
    transition: 0.3s;
  }
  
  .blog .entry .entry-title a:hover {
    color:#E4641C;
  }
  
  .blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #2b99f8;
  }
  
  .blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  
  .blog .entry .entry-meta ul li+li {
    padding-left: 20px;
  }
  
  .blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
  }
  
  .blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
  }
  
  .blog .entry .entry-content p {
    line-height: 24px;
  }
  
  .blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
  }
  
  .blog .entry .entry-content .read-more a {
    display: inline-block;
    background:#E4641C;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .blog .entry .entry-content .read-more a:hover {
    background: #f7b93c;
  }
  
  .blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }
  
  .blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
  }
  
  .blog .entry .entry-content blockquote p {
    color: #444444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
  }
  
  .blog .entry .entry-content blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #0880e8;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
  }
  
  .blog .entry .entry-footer i {
    color: #8ec8fb;
    display: inline;
  }
  
  .blog .entry .entry-footer a {
    color: #138df7;
    transition: 0.3s;
  }
  
  .blog .entry .entry-footer a:hover {
    color:#E4641C;
  }
  
  .blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .cats li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .tags li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags li+li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
  }
  
  .blog .entry .entry-footer .share {
    font-size: 16px;
  }
  
  .blog .entry .entry-footer .share i {
    padding-left: 5px;
  }
  
  .blog .entry-single {
    margin-bottom: 30px;
  }
  
  .blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-author img {
    width: 120px;
    margin-right: 20px;
  }
  
  .blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #0880e8;
  }
  
  .blog .blog-author .social-links {
    margin: 0 10px 10px 0;
  }
  
  .blog .blog-author .social-links a {
    color: rgba(8, 128, 232, 0.5);
    margin-right: 5px;
  }
  
  .blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
  }
  
  .blog .blog-comments {
    margin-bottom: 30px;
  }
  
  .blog .blog-comments .comments-count {
    font-weight: bold;
  }
  
  .blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
  }
  
  .blog .blog-comments .comment .comment-img {
    margin-right: 14px;
  }
  
  .blog .blog-comments .comment .comment-img img {
    width: 60px;
  }
  
  .blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444444;
    transition: 0.3s;
  }
  
  .blog .blog-comments .comment h5 a:hover {
    color:#E4641C;
  }
  
  .blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #0880e8;
  }
  
  .blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
  }
  
  .blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #2b99f8;
    margin-bottom: 5px;
  }
  
  .blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
  }
  
  .blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
  }
  
  .blog .blog-comments .reply-form p {
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #fad386;
  }
  
  .blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #fad386;
  }
  
  .blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
  }
  
  .blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #0880e8;
  }
  
  .blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #138df7;
  }
  
  .blog .blog-pagination {
    color: #0880e8;
  }
  
  .blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
  }
  
  .blog .blog-pagination li a {
    color: #0880e8;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .blog .blog-pagination li.active,
  .blog .blog-pagination li:hover {
    background:#E4641C;
  }
  
  .blog .blog-pagination li.active a,
  .blog .blog-pagination li:hover a {
    color: #fff;
  }
  
  .blog .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #0665b7;
    position: relative;
  }
  
  .blog .sidebar .sidebar-item {
    margin-bottom: 30px;
  }
  
  .blog .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
  }
  
  .blog .sidebar .search-form form input[type=text] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 40px);
  }
  
  .blog .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background:#E4641C;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
  }
  
  .blog .sidebar .search-form form button i {
    line-height: 0;
  }
  
  .blog .sidebar .search-form form button:hover {
    background: #f8c255;
  }
  
  .blog .sidebar .categories ul {
    list-style: none;
    padding: 0;
  }
  
  .blog .sidebar .categories ul li+li {
    padding-top: 10px;
  }
  
  .blog .sidebar .categories ul a {
    color: #0665b7;
    transition: 0.3s;
  }
  
  .blog .sidebar .categories ul a:hover {
    color:#E4641C;
  }
  
  .blog .sidebar .categories ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
  }
  
  .blog .sidebar .recent-posts .post-item+.post-item {
    margin-top: 15px;
  }
  
  .blog .sidebar .recent-posts img {
    width: 80px;
    float: left;
  }
  
  .blog .sidebar .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
  }
  
  .blog .sidebar .recent-posts h4 a {
    color: #0665b7;
    transition: 0.3s;
  }
  
  .blog .sidebar .recent-posts h4 a:hover {
    color:#E4641C;
  }
  
  .blog .sidebar .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #aaaaaa;
  }
  
  .blog .sidebar .tags {
    margin-bottom: -10px;
  }
  
  .blog .sidebar .tags ul {
    list-style: none;
    padding: 0;
  }
  
  .blog .sidebar .tags ul li {
    display: inline-block;
  }
  
  .blog .sidebar .tags ul a {
    color: #0880e8;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid white;
    display: inline-block;
    transition: 0.3s;
  }
  
  .blog .sidebar .tags ul a:hover {
    color: #fff;
    border: 1px solid#E4641C;
    background:#E4641C;
  }
  
  .blog .sidebar .tags ul a span {
    padding-left: 5px;
    color: white;
    font-size: 14px;
  }
  
 