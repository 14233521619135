#prospera .promotor{
    position: relative;
    background-color:#ff6100 ;
    height: auto;
}

#prospera .promotor img{
    width: 90%;
    height: auto;
    position: sticky;
    z-index: 1000;
    margin-top: -90px;
}
#prospera h1{
    margin-top: 40px;
    color: #001a4c;
    font-weight: 600;
    font-family: Verdana, sans-serif;
    font-size: 2em;
}
#prospera ol{
    font-size: 1.5em;
    font-weight: 400;
    font-family: Verdana, sans-serif;
    margin-bottom: 50px;
}
#prospera p{
    
    color: #001a4c;
    font-size: 1.5em;
    font-weight: 400;
    font-family: Verdana, sans-serif;
}
#prospera .conteudo a{
    background-color:#0b9216 ;
    padding: 10px;
   font-size: 30px;
   border-radius: 15px;

}
#prospera .conteudo a:hover{
    background-color:#1faf38 ;
    padding: 10px;
   font-size: 30px;
   border-radius: 15px;

}
#prospera .conteudo span{
    color: #fff;
    font-weight: 600;
    font-size: 0.8em;
    margin: 2px;
}