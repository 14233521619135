
a{
    text-decoration: none;
}

.btn-netsim{
    font-family:sans-serif;
    font-size: 15;
    font-weight:500;
    background-color:#E4641C;
    color:#fff;
    border-radius: 30px;
    border:none;
    padding:10px;
    cursor:pointer;

    

}
.btn-netsim:hover{
    color:#fff;
    background-color:#E4643d;
    border:1;
    border-color: #d3d3d3;
}
.titulo-home{
    text-align: right; 
    font-family:sans-serif; 
    font-size: 49px; 
    letter-spacing: 3.9px; 
    word-spacing: 4px; 
    line-height: 49px; 
    color: #fff; 
    font-weight: 600;

}
.subtitulo-home{
    font-family: sans-serif; 
    font-size: 39px; 
    letter-spacing: 3.9px; 
    word-spacing: 4px; 
    line-height: 49px; 
    color: #ffffff; 
    font-weight: 300; 
    text-align: right;
}
.bg-app-netsim{
    width:100%;
    height:450px;
    background-image: url('https://netsimtelecom.com.br/wp-content/uploads/2021/12/app.jpg');

 
}
.card-valores-1{
    
    background-color: #E4641C;
    color:#fff;
    text-align: center;
    padding: 30px;
    width: 200px;
    margin: 0;

}
.card-valores-2{
    background-color:#00284A ;
    color: #fff;
    text-align: center;
    padding: 30px;
    width: 200px;
    font-size: 15px;
    margin: 0;




}
#f-laranja{
    color:#E4641C;
}
#bg-laranja{
    background-color: #E4641C;
}
#bg-laranja:hover {
    background-color:#00284A ;
}
.card-laranja{
    background-color: #E4641C;
}
#progress-bar-100{
    width: 99%;
}
#apps{
    width: auto;
}
#apps .img{
   width: 400px;
   border-radius:10px ;
}