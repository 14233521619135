
.bgFundo{
  background-color: #f1f1f1;
}
.fundoSuperior{
    padding: 20px;
    border:none;
    z-index: 1;
    width: 100%;
    height:500px;
    background-repeat: no-repeat;
    position:relative;

}
#cardCentral{
    border: none;
    z-index: 3;
    background:linear-gradient(180deg,rgba(255,255,255,1) 37%, rgba(255,255,255,0) 93%, rgba(255,255,255,0) 100%);
    position: relative;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }

  .b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }

  .b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
  }

  .bi {
    vertical-align: -.125em;
    fill: currentColor;
  }

  .nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
  }

  .nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  .custom-tooltip {
    --bs-tooltip-bg: var(--bs-primary);
  }
 .card-planos:hover{
  box-shadow: 5px 5px 5px #808080;
  border-radius:10px 0px 10px 5px;
 }

 .pricing-title{
    color: #fff;
    font-family: "Exo", Sans-serif;
    font-size: 50px;
    font-weight: 900;
    text-transform: uppercase;
    font-style: italic;
    line-height: 1.2em;
 }
 .pricing-valor{
    color: #fff;
    font-family: "exo", Sans-serif;
    font-size: 40px;
    font-weight: 900;
    line-height: 0.5;
 }
 .pricing-body{
  color: #fff;
    font-family: "Exo", Sans-serif;
    font-size: 35px;
    font-weight: 800;
    text-transform: uppercase;
    font-style: italic;
    line-height: 1;

 }
 