@import url('https://fonts.googleapis.com/css2?family=Baloo+Chettan+2:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

.link-orange {
  text-decoration: none;
}
.text-orange {
  color: #E4641C;
}

.link-orange:hover {
  color: #E4641C;

}

.link-reset {

  text-decoration: none;
  color: #fff;

}

.link-reset:hover {

  text-decoration: none;
  color: #fff;

}

.btn-netsim {
  font-family: sans-serif;
  font-size: 15;
  font-weight: 500;
  background-color: #E4641C;
  color: #fff;
  border-radius: 30px;
  border: none;
  padding: 10px;
  cursor: pointer;



}

.btn-netsim:hover {
  color: #fff;
  background-color: #00284A;
  border: 1;
  border-color: #d3d3d3;
}

.titulo-home {
  text-align: right;
  font-family: sans-serif;
  font-size: 49px;
  letter-spacing: 3.9px;
  word-spacing: 4px;
  line-height: 49px;
  color: #fff;
  font-weight: 600;

}

.subtitulo-home {
  font-family: sans-serif;
  font-size: 39px;
  letter-spacing: 3.9px;
  word-spacing: 4px;
  line-height: 49px;
  color: #ffffff;
  font-weight: 300;
  text-align: right;
}

.card-valores-1 {

  background-color: #E4641C;
  color: #fff;
  text-align: center;
  padding: 30px;
  width: 200px;
  margin: 0;

}

.card-valores-2 {
  background-color: #00284A;
  color: #fff;
  text-align: center;
  padding: 30px;
  width: 200px;
  font-size: 15px;
  margin: 0;




}

#f-laranja {
  color: #E4641C;
}

#bg-laranja {
  background-color: #E4641C;
}

#bg-laranja:hover {
  background-color: #00284A;
}

.card-laranja {
  background-color: #E4641C;
}

#progress-bar-100 {
  width: 99%;
}

.font-orange {
  font-family: sans-serif;
  font-size: 15;
  color: #E4641C;
}

.text-custom {
  font-family: 'Baloo Chettan 2', cursive;
  font-size: 2.5rem;

}

.btn-outline-orange {
  background-color: transparent;
  border-radius: 0.375em;
  border: 1px solid #e4651c;
  color: #e4651c;
  display: inline-block;
  cursor: pointer;
  font-size: 1.6rem;
  padding: 0.75rem 0.375rem;
  text-decoration: none;
  font-weight: 100;
  line-height: 1.5;

}

.btn-outline-orange:hover {
  background-color: #e4651c;
  color: #ffffff;
}

.border-orange {
  background-color: transparent;
  border-radius: 0.375em;
  border: 1px solid #e4651c;
  display: inline-block;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem 0.375rem;
  text-decoration: none;
  font-weight: 500;
  line-height: 1.5;
}

.divider-orange {
  background-color: transparent;
  border-bottom: 1px solid #e4651c;
  display: inline-block;
  width: 100%;
}

.hover-orange:hover {
  color: #e4651c;
}

.bg-orange {
  background-color: #e4651c;
}

.orange {
  color: #E4641C;
}

Link {
  text-decoration: none;
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer-cliente {
  background: #fff;
  padding: 0 0 30px 0;
  color: #2e2e2e;
  font-size: 14px;
}

#footer-cliente .footer-top {
  padding: 10px 0 30px 0;
}

#footer-cliente .footer-top .footer-info {
  margin-bottom: 15px;
  background: #191919;
  color: #fff;
  border-top: 4px solid #E4641C;
  text-align: center;
  padding: 30px 20px;
}

#footer-cliente .footer-top .footer-info h3 {
  font-size: 36px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer-cliente .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}

#footer-cliente .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #262626;
  line-height: 1.8;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  color: #fff;
}

#footer-cliente .footer-top .social-links a:hover {
  background: #E4641C;
  color: #fff;
  text-decoration: none;
}

#footer-cliente .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  position: relative;
  padding-bottom: 12px;
}

#footer-cliente .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer-cliente .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer-cliente .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #E4641C;
  font-size: 18px;
  line-height: 1;
}

#footer-cliente .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer-cliente .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer-cliente .footer-top .footer-links ul a {
  color: #2e2e2e;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
}

#footer-cliente .footer-top .footer-links ul a:hover {
  color: #E4641C;
}

#footer-cliente .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4;
}

#footer-cliente .footer-top .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer-cliente .footer-top .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #cc1616;
  color: #fff;
  transition: 0.3s;
  border-radius: 4;
}

#footer-cliente .footer-top .footer-newsletter form input[type=submit]:hover {
  background: #E4641C;
}

#footer-cliente .copyright {
  border-top: 1px solid #262626;
  text-align: center;
  padding-top: 30px;
}

#footer-cliente .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

#footer-cliente .credits a {
  color: #E4641C;
}

@media (max-width: 575px) {
  #footer-cliente .footer-top .footer-info {
    margin: -20px 0 30px 0;
  }
}



.logo-img {
  height: 6.0rem;
}

#menu {
  background: rgb(205, 205, 205);
  background: linear-gradient(90deg, rgb(255, 255, 255) 20%, rgba(213, 213, 213, 0.6) 41%, rgba(255, 255, 255, 1) 100%);
  backdrop-filter: blur(5px);
  text-decoration: none
}

.navlink {
  color: #191919;
}

.nav-link:focus,
.nav-link:hover {
  color: #E4641C;

}


.dropdown-toggle .show::after {
  rotate: 180deg;
}

.navbar-toggler {
  border: none;
  box-shadow: none
}

.navbar-toggler:focus {
  border: none;
  box-shadow: none;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color: #E4641C;
}

.dropdown-menu {
  margin-top: 60px;
  width: 300px;
  border-radius: 0px;
  border: none;
  padding: 0;

}

.list-group-item {
  font-weight: 500;
}

.list-group-item:hover {
  font-weight: 500;
  background-color: rgb(241, 241, 241);
}

.list-group-item a {
  text-decoration: none;
  color: #191919;
}

.list-group-item .btn {
  padding: 2px;
  margin-left: 3px;
  font-size: smaller;
}


/* Tabs planos */

.nav-tabs {

  border: 3px solid #afafaf;
  padding: 0;
  font-size: 0.8em;
  width: 25em;
  border-radius: 50px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.nav-tabs .nav-link:hover {

  border-radius: 50px;


}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {

  color: #fff;
  background-color: rgb(0, 40, 74);
  margin-bottom: 0;
  border-radius: 50px;
}

@media only screen and (max-width: 567px) {
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
  }
}