.image-response {
    width: 60rem;
  
}

.scroll-container{
overflow: auto;
white-space: nowrap;
padding: 5px 70px 5px 20px;
background: transparent;
height: 100%;
border-radius:15px;
}

.gridscroll{
display:inline-block;
}

.gridscroll img {
margin-right:22px;
}