
#card-fibra .card{
  border: none;
 background-color: transparent;
}
#card-fibra .card .text-mega{
  color: #002976;
}
#card-fibra .link-orange{
color: #ff5e00;
}
#card-fibra .link-orange:hover{
  color: #ca4a00;
  }

#card-fibra .card-planos{
  padding: 15px 0px;
  background: #fff;
  border: solid #ccc 1px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

#card-fibra .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 0.7em;
  padding: 1px 0 3px 0;
  background: #E4641C;
  color: #fff;
}



#card-fibra .offer_title {
    padding-top: 11px;
    color: #00234b;
    font-size:2.9rem;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
    line-height:20px;
    letter-spacing: -0.06em;
    font-weight: 800;
}
#card-fibra .title{
  color: #ff5e00;
  font-weight: 700;
  font-size: 2em;
}
#card-fibra .valor{
  color: #00234b;
  font-size: 3.5rem;
  
}

/* #card-fibra .card-footer{
  height: 360px;
  border-radius: 20px;
  border: solid 1px #001a4c;
}
#card-fibra .card-footer-empresa{
  height: 140px;
  border-radius: 20px;
  border: solid 1px #001a4c;
} */
#card-fibra .card-footer{
  background-color: rgb(236, 236, 236);
  height: 310px;
  border-radius: 20px;
  border: 0px transparent;
}
#card-fibra .card-footer-empresa{
  padding: 10px;
  background-color: rgb(236, 236, 236);
  height: 140px;
  border-radius: 20px;
  border: 0px transparent;
}
.card-footer ul {
    padding: 0;
    list-style: none;
    line-height: 20px;
    font-size: 14px;
  }
  
  .card-footer li{
    font-size: 1.0rem;
    color: #001a4c;
    font-weight: 500;
  }
  .card-footer-empresa li{
    font-size: 1.0rem;
    color: #001a4c;
    font-weight: 500;
  }
  
  .card-footer ul i {
    color: #0880e8;
    font-size: 18px;
    padding-right: 4px;
  }
  
  .card-footer ul .no {
    color: #ccc;
    text-decoration: line-through;
  }

  #card-fibra .card-body button{
    color: #fff;
    font-weight: 500;
    background-color: #002976;
    border: 1px solid #001a4c;
   box-shadow: #001a4c 0px 2px;
  }
  #card-fibra .card-body button:hover{
    color: #fff;
    font-weight: 500;
    background-color: #033796;
    border: 1px solid #001a4c;
   box-shadow: #001a4c 0px 5px;
  }