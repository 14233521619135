.bg-verde{
    background-color: #5fc192;
}

.bg-gradiente{
    background: rgb(0,180,145);
    background: linear-gradient(157deg, rgba(0,180,145,1) 9%, rgba(0,91,120,1) 24%, rgba(0,26,101,1) 50%);
}
#grade{
    width: 100%;
}
#grade img{
    width:auto;
    height: 80vh;
}